import React, { useState } from 'react';
import FuturisticBackground from '../../FuturisticBackground.js';  
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';

const MissionsPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const missions = [
    {
      id: 'overview',
      title: 'Overview',
      description: 'Why did we start this project?',
      target: null
    },
    {
      id: 'technical',
      title: 'Technical Development',
      description: 'What is our philosophy behind technical development?',
      target: null
    }
  ];

  return (
    <div className="min-h-screen relative text-white overflow-hidden">
      <FuturisticBackground />
      
      <nav className="relative bg-black/30 backdrop-blur-md border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div className="relative">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full blur opacity-75 animate-pulse"/>
                <img src="/logo.png" alt="Logo" className="relative h-8 w-8 sm:h-10 sm:w-10 rounded-full border border-white/10" />
              </div>
              <span className="ml-2 text-lg sm:text-xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                MegaMeow
              </span>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-6">
              <Link to="/" className="text-gray-300 hover:text-white transition duration-300">Home</Link>
              <Link to="/missions" className="text-white transition duration-300">Missions</Link>
              <Link to="/wallet" className="text-gray-300 hover:text-white transition duration-300">Wallet</Link>
              <Link to="/roadmap" className="text-gray-300 hover:text-white transition duration-300">Roadmap</Link>
              <Link to="/token-allocation" className="text-gray-300 hover:text-white transition duration-300">Token Allocation</Link>
            </div>

            {/* Mobile Menu Button */}
            <div className="flex md:hidden items-center">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="text-gray-300 hover:text-white"
              >
                {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {mobileMenuOpen && (
          <div className="md:hidden bg-black/95 backdrop-blur-xl">
            <div className="px-4 pt-2 pb-4 space-y-4">
              <Link to="/" className="block text-gray-300 hover:text-white transition duration-300 py-2">Home</Link>
              <Link to="/missions" className="block text-white transition duration-300 py-2">Missions</Link>
              <Link to="/wallet" className="block text-gray-300 hover:text-white transition duration-300 py-2">Wallet</Link>
              <Link to="/roadmap" className="block text-gray-300 hover:text-white transition duration-300 py-2">Roadmap</Link>
              <Link to="/token-allocation" className="block text-gray-300 hover:text-white transition duration-300 py-2">Token Allocation</Link>
            </div>
          </div>
        )}
      </nav>
      
      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
        <div className="relative">
          <div className="absolute -inset-4 bg-violet-600/20 blur-3xl rounded-full"></div>
          
          <h1 className="relative text-4xl sm:text-5xl lg:text-7xl font-bold text-center mb-12 bg-gradient-to-r from-violet-300 via-fuchsia-200 to-blue-300 bg-clip-text text-transparent animate-gradient-x">
            Our Missions
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {missions.map((mission) => (
              <Link 
                key={mission.id}
                to={`/missions/${mission.id}`}
                className="relative group transform transition-all duration-500 hover:scale-105"
              >
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 via-pink-500 to-blue-500 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-gradient-xy"/>
                
                <div className="relative bg-black/40 backdrop-blur-xl p-6 rounded-lg border border-white/10 hover:border-white/20 transition h-48 flex flex-col justify-between">
                  <div>
                    <h3 className="text-xl font-bold text-white mb-4 bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                      {mission.title}
                    </h3>
                    <p className={`text-gray-300 mb-4 text-lg`}>
                      {mission.description}
                    </p>
                  </div>
                  {mission.target && (
                    <div className="text-purple-400 text-sm">Target: {mission.target}</div>
                  )}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionsPage;