import React, { useEffect, useRef } from 'react';

const FuturisticBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    let time = 0;
    const draw = () => {
      time += 0.01;

      ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const gridSize = 30;

      for (let layer = 0; layer < 3; layer++) {
        const layerOffset = layer * 0.5;

        for (let i = 0; i < canvas.width; i += gridSize) {
          for (let j = 0; j < canvas.height; j += gridSize) {
            const waveX = Math.sin(time + j * 0.05 + layerOffset) * 15;
            const waveY = Math.cos(time + i * 0.05 + layerOffset) * 15;

            const x = i + waveX;
            const y = j + waveY;

            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            const distanceFromCenter = Math.sqrt(
              Math.pow(x - centerX, 2) + 
              Math.pow(y - centerY, 2)
            );

            const maxDistance = Math.sqrt(
              Math.pow(canvas.width / 2, 2) + 
              Math.pow(canvas.height / 2, 2)
            );

            const opacity = 0.5 - (distanceFromCenter / maxDistance);

            const hue = (time * 20 + distanceFromCenter + layer * 120) % 360;
            const saturation = 80 + Math.sin(time) * 20;
            const lightness = 60 + Math.cos(time) * 10;

            ctx.fillStyle = `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity})`;

            const pointSize = 2 + Math.sin(time + distanceFromCenter * 0.01) * 1;
            ctx.beginPath();
            ctx.arc(x, y, pointSize, 0, Math.PI * 2);
            ctx.fill();

            if (layer === 1 && i < canvas.width - gridSize && j < canvas.height - gridSize) {
              const nextX = i + gridSize + Math.sin(time + (j + gridSize) * 0.05) * 15;
              const nextY = j + gridSize + Math.cos(time + (i + gridSize) * 0.05) * 15;

              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.lineTo(nextX, y);
              ctx.lineTo(nextX, nextY);
              ctx.strokeStyle = `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity * 0.3})`;
              ctx.lineWidth = 0.5;
              ctx.stroke();
            }
          }
        }
      }

      animationFrameId = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas 
      ref={canvasRef} 
      className="fixed inset-0 w-full h-full pointer-events-none"
      style={{ 
        background: 'radial-gradient(circle at 50% 50%, #1a0b2e, #000)',
        filter: 'contrast(1.2) brightness(1.1)'
      }}
    />
  );
};

export default FuturisticBackground;