import React, { useEffect, useRef } from 'react';

const GalaxyBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    class Star {
      constructor() {
        this.reset();
      }

      reset() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.z = Math.random() * 1500 + 500; // 控制深度感
        this.brightness = Math.random();
        this.size = Math.random() * 2;
        this.speed = Math.random() * 1.2 + 0.5; // Increased from 0.8 + 0.3
        this.color = `hsl(${Math.random() * 60 + 200}, ${Math.random() * 30 + 70}%, ${Math.random() * 30 + 70}%)`;
      }

      update() {
        this.z -= this.speed;
        if (this.z <= 0) {
          this.reset();
        }

        const factor = 100 / this.z;
        const projectedX = (this.x - canvas.width / 2) * factor + canvas.width / 2;
        const projectedY = (this.y - canvas.height / 2) * factor + canvas.height / 2;

        if (projectedX < 0 || projectedX > canvas.width || 
            projectedY < 0 || projectedY > canvas.height) {
          this.reset();
        }

        return {
          x: projectedX,
          y: projectedY,
          size: Math.max(0.5, this.size * factor),
          brightness: this.brightness,
          color: this.color
        };
      }
    }

    class Nebula {
      constructor() {
        this.points = [];
        const numPoints = 100;
        for (let i = 0; i < numPoints; i++) {
          this.points.push({
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            size: Math.random() * 100 + 50,
            hue: Math.random() * 60 + 200,
            opacity: Math.random() * 0.2
          });
        }
      }

      draw(ctx, time) {
        this.points.forEach(point => {
          const gradient = ctx.createRadialGradient(
            point.x, point.y, 0,
            point.x, point.y, point.size
          );
          
          const opacity = point.opacity * (0.5 + Math.sin(time + point.x * 0.01) * 0.2);
          
          gradient.addColorStop(0, `hsla(${point.hue}, 70%, 60%, ${opacity})`);
          gradient.addColorStop(1, 'transparent');
          
          ctx.fillStyle = gradient;
          ctx.fillRect(
            point.x - point.size,
            point.y - point.size,
            point.size * 2,
            point.size * 2
          );
        });
      }
    }

    const stars = Array.from({ length: 1000 }, () => new Star());
    const nebula = new Nebula();
    let time = 0;

    const draw = () => {
      time += 0.001; // Increased from 0.0005

      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      nebula.draw(ctx, time);

      stars.forEach(star => {
        const projected = star.update();
        
        ctx.beginPath();
        ctx.arc(projected.x, projected.y, projected.size, 0, Math.PI * 2);
        ctx.fillStyle = projected.color;
        ctx.fill();

        const gradient = ctx.createRadialGradient(
          projected.x, projected.y, 0,
          projected.x, projected.y, projected.size * 4
        );
        gradient.addColorStop(0, `rgba(255, 255, 255, ${projected.brightness * 0.2})`);
        gradient.addColorStop(1, 'transparent');
        ctx.fillStyle = gradient;
        ctx.fill();
      });

      animationFrameId = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas 
      ref={canvasRef} 
      className="fixed inset-0 w-full h-full pointer-events-none"
      style={{ 
        background: 'radial-gradient(circle at 50% 50%, #090014, #000)',
        filter: 'contrast(1.2) brightness(1.1)'
      }}
    />
  );
};

export default GalaxyBackground;