import React, { useEffect, useRef } from 'react';

const WalletBackground = () => {  // 改为 WalletBackground
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    // Cube setup
    const createCube = (centerX, centerY, size) => {
      const half = size / 2;
      return [
        { x: centerX - half, y: centerY - half, z: -half },
        { x: centerX + half, y: centerY - half, z: -half },
        { x: centerX + half, y: centerY + half, z: -half },
        { x: centerX - half, y: centerY + half, z: -half },
        { x: centerX - half, y: centerY - half, z: half },
        { x: centerX + half, y: centerY - half, z: half },
        { x: centerX + half, y: centerY + half, z: half },
        { x: centerX - half, y: centerY + half, z: half },
      ];
    };

    const cubes = [];
    const cubeCount = 8;
    for (let i = 0; i < cubeCount; i++) {
      const angle = (i * Math.PI * 2) / cubeCount;
      const radius = 150;
      cubes.push({
        x: canvas.width / 2 + Math.cos(angle) * radius,
        y: canvas.height / 2 + Math.sin(angle) * radius,
        size: 15 + Math.random() * 10,
      });
    }

    const rotatePoint = (point, centerX, centerY, angleX, angleY) => {
      let y = point.y - centerY;
      let z = point.z;
      let rotatedY = y * Math.cos(angleX) - z * Math.sin(angleX);
      let rotatedZ = y * Math.sin(angleX) + z * Math.cos(angleX);
      point.y = rotatedY + centerY;
      point.z = rotatedZ;

      let x = point.x - centerX;
      z = point.z;
      let rotatedX = x * Math.cos(angleY) - z * Math.sin(angleY);
      rotatedZ = x * Math.sin(angleY) + z * Math.cos(angleY);
      point.x = rotatedX + centerX;
      point.z = rotatedZ;

      return point;
    };

    const drawCube = (centerX, centerY, size, time) => {
      const vertices = createCube(centerX, centerY, size);
      const rotatedVertices = vertices.map(vertex => 
        rotatePoint({...vertex}, centerX, centerY, time * 0.2, time * 0.3)
      );

      const edges = [
        [0,1], [1,2], [2,3], [3,0],
        [4,5], [5,6], [6,7], [7,4],
        [0,4], [1,5], [2,6], [3,7]
      ];

      edges.forEach(([i, j]) => {
        ctx.beginPath();
        ctx.moveTo(rotatedVertices[i].x, rotatedVertices[i].y);
        ctx.lineTo(rotatedVertices[j].x, rotatedVertices[j].y);
        const depth = (rotatedVertices[i].z + rotatedVertices[j].z) / 2;
        const hue = (time * 20 + depth) % 360;
        ctx.strokeStyle = `hsla(${hue}, 70%, 60%, 0.4)`;
        ctx.lineWidth = 1;
        ctx.stroke();
      });

      return rotatedVertices;
    };

    let time = 0;
    const draw = () => {
      time += 0.005;

      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const gridSize = 40;
      for (let i = 0; i < canvas.width; i += gridSize) {
        for (let j = 0; j < canvas.height; j += gridSize) {
          const waveX = Math.sin(time + j * 0.05) * 10;
          const waveY = Math.cos(time + i * 0.05) * 10;
          
          const x = i + waveX;
          const y = j + waveY;
          
          const centerX = canvas.width / 2;
          const centerY = canvas.height / 2;
          const distanceFromCenter = Math.sqrt(
            Math.pow(x - centerX, 2) + 
            Math.pow(y - centerY, 2)
          );
          
          const opacity = 0.3 - (distanceFromCenter / Math.max(canvas.width, canvas.height));
          if (opacity > 0) {
            const hue = (time * 20 + distanceFromCenter) % 360;
            ctx.fillStyle = `hsla(${hue}, 80%, 60%, ${opacity})`;
            ctx.beginPath();
            ctx.arc(x, y, 1, 0, Math.PI * 2);
            ctx.fill();
          }
        }
      }

      const allVertices = cubes.map((cube) => 
        drawCube(cube.x, cube.y, cube.size, time)
      );

      for (let i = 0; i < cubes.length; i++) {
        const nextIndex = (i + 1) % cubes.length;
        const currentCube = allVertices[i];
        const nextCube = allVertices[nextIndex];
        
        const distance = Math.sqrt(
          Math.pow(currentCube[0].x - nextCube[0].x, 2) +
          Math.pow(currentCube[0].y - nextCube[0].y, 2)
        );

        if (distance < 250) {
          ctx.beginPath();
          ctx.moveTo(currentCube[0].x, currentCube[0].y);
          ctx.lineTo(nextCube[0].x, nextCube[0].y);
          const hue = (time * 20) % 360;
          ctx.strokeStyle = `hsla(${hue}, 70%, 60%, ${0.2 - distance/1000})`;
          ctx.lineWidth = 0.5;
          ctx.stroke();
        }
      }

      for (let i = 0; i < 10; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        ctx.beginPath();
        ctx.arc(x, y, Math.random() * 1, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${Math.random() * 0.3})`;
        ctx.fill();
      }

      animationFrameId = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <canvas 
        ref={canvasRef} 
        className="fixed inset-0 w-full h-full"
        style={{ 
          background: 'radial-gradient(circle at 50% 50%, #1a0b2e, #000)',
          filter: 'contrast(1.2) brightness(1.1)'
        }}
      />
      <div className="absolute inset-0 mix-blend-screen opacity-20 pointer-events-none">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,rgba(255,0,128,0.2),transparent_70%)]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_70%,rgba(0,255,255,0.2),transparent_70%)]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(128,0,255,0.2),transparent_70%)]" />
      </div>
    </div>
  );
};

export default WalletBackground;  