import React, { useState } from 'react';
import GalaxyBackground from '../../GalaxyBackground';
import { Menu, X } from 'lucide-react'; // Removed Sun, Globe
import { Link, useNavigate } from 'react-router-dom';

const MissionOverview = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate(); // 使用 useNavigate 钩子

  return (
    <div className="min-h-screen relative text-white overflow-hidden">
      <GalaxyBackground />

      {/* Navigation Bar */}
      <nav className="relative bg-black/30 backdrop-blur-md border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div className="relative">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full blur opacity-75 animate-pulse"/>
                <img src="/logo.png" alt="Logo" className="relative h-8 w-8 sm:h-10 sm:w-10 rounded-full border border-white/10" />
              </div>
              <span className="ml-2 text-lg sm:text-xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                MegaMeow
              </span>
            </div>

            {/* Desktop Navigation - Removed icons */}
            <div className="hidden md:flex items-center space-x-6">
              <Link to="/" className="text-gray-300 hover:text-white transition duration-300">Home</Link>
              <Link to="/missions" className="text-white transition duration-300">Missions</Link>
              <Link to="/wallet" className="text-gray-300 hover:text-white transition duration-300">Wallet</Link>
              <Link to="/roadmap" className="text-gray-300 hover:text-white transition duration-300">Roadmap</Link>
              <Link to="/token-allocation" className="text-gray-300 hover:text-white transition duration-300">Token Allocation</Link>
            </div>

            {/* Mobile Menu Button */}
            <div className="flex md:hidden items-center">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="text-gray-300 hover:text-white"
              >
                {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation - Removed icons */}
        {mobileMenuOpen && (
          <div className="md:hidden bg-black/95 backdrop-blur-xl">
            <div className="px-4 pt-2 pb-4 space-y-4">
              <Link to="/" className="block text-gray-300 hover:text-white transition duration-300 py-2">Home</Link>
              <Link to="/missions" className="block text-white transition duration-300 py-2">Missions</Link>
              <Link to="/wallet" className="block text-gray-300 hover:text-white transition duration-300 py-2">Wallet</Link>
              <Link to="/roadmap" className="block text-gray-300 hover:text-white transition duration-300 py-2">Roadmap</Link>
              <Link to="/token-allocation" className="block text-gray-300 hover:text-white transition duration-300 py-2">Token Allocation</Link>
            </div>
          </div>
        )}
      </nav>

      {/* 内容容器 */}
      <div className="relative max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
        {/* 标题部分，修正了字母 "g" 被遮挡的问题 */}
        <div className="text-center mb-16 relative">
          <div className="absolute inset-0 bg-violet-600/20 blur-3xl rounded-full"></div>
          <h1 className="relative text-3xl sm:text-4xl lg:text-5xl font-bold mb-6 bg-gradient-to-r from-violet-300 via-fuchsia-200 to-blue-300 bg-clip-text text-transparent animate-gradient-x">
            Mission Overview
          </h1>
          <p className="text-lg sm:text-xl text-gray-300 relative">
            Our journey to revolutionize the Chia ecosystem
          </p>
        </div>

        {/* 特殊引用部分，放大了文字框 */}
        <div className="relative">
          {/* 放大后的特殊引用容器 */}
          <div className="group relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-2xl blur-lg opacity-75 group-hover:opacity-100 transition duration-500"></div>
            <div className="relative backdrop-blur-lg bg-black/40 rounded-2xl p-8 sm:p-10 border border-white/10">
              <blockquote className="relative text-gray-300 italic text-xl sm:text-2xl leading-relaxed">
                We believe Chia has advanced technology that solves real-world issues by leveraging the intrinsic properties of the blockchain. However, even the most genuine believers who started from the beginning may feel frustrated when the price goes down. But we always believe that gold will shine when the time is right, and we will steadfastly stand by our values with Chia without questioning its future.<br/>
                The total supply of the MegaMeow token will constantly decrease each time Chia hits a milestone, whether decided on our roadmap or through community votes for milestone events.<br/>
                We are not just a token; we are a community that believes in the future of Chia and will always support it.
              </blockquote>
            </div>
          </div>
        </div>

        {/* Enhanced Navigation Buttons */}
        <div className="flex justify-between items-center mt-12">
          <Link
            to="/missions"
            className="group relative px-6 py-3 bg-black/40 backdrop-blur-sm rounded-lg border border-white/10 hover:border-white/20 transition-all duration-300"
          >
            <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-30 group-hover:opacity-100 transition"></div>
            <span className="relative text-gray-300 group-hover:text-white">← Back to Missions</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MissionOverview;