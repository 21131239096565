import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import RoadMapBackground from './RoadMapBackground';

const RoadMapPhase = ({ phase, title, items, status, isMystery }) => (
  <div className="relative group min-h-[24rem]">
    <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-1000"/>
    <div className={`relative bg-black/40 backdrop-blur-xl p-6 rounded-lg border border-white/10 hover:border-white/20 transition h-full flex flex-col ${isMystery ? 'select-none' : ''}`}>
      <div className="flex items-center gap-4 mb-4">
        <div className="text-purple-400 text-xl font-bold">{phase}</div>
        <div className={`px-3 py-1 rounded-full text-sm ${
          status === 'completed' ? 'bg-green-500/20 text-green-300' :
          status === 'current' ? 'bg-blue-500/20 text-blue-300' :
          'bg-gray-500/20 text-gray-300'
        }`}>
          {status === 'completed' ? 'Completed' : 
           status === 'current' ? 'In Progress' : 'Upcoming'}
        </div>
      </div>
      
      {isMystery ? (
        // Mystery overlay
        <div className="absolute inset-0 bg-black/60 backdrop-blur-sm rounded-lg flex flex-col items-center justify-center">
          <div className="text-6xl mb-4">🔒</div>
          <p className="text-gray-400 text-center px-4">
            Content will be revealed soon...
          </p>
        </div>
      ) : (
        // Regular content
        <>
          <h3 className="text-xl font-bold text-white mb-4 bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">
            {title}
          </h3>
          <ul className="space-y-3 flex-grow">
            {items.map((item, index) => (
              <li key={index} className="flex items-start gap-3 text-gray-300">
                <span className="text-purple-400 mt-1">•</span>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  </div>
);

const RoadMapPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const roadmapData = [
    {
      phase: "Phase 1",
      title: "Foundation & Launch",
      status: "current", 
      items: [
        "Launch the MegaMeow Token on Chia Blockchain",
        "Finalize Tokenomics and Custody Plan",
        "AirDrop the MegaMeow Token to the Chia Community",
        "Website Launch"
      ]
    },
    {
      phase: "Phase 2",
      title: "Growth & Integration",
      status: "current",
      items: [
        "DEX Listings & Liquidity Provision",
        "Strategic Marketing Campaign",
        "Community Governance Implementation",
        "Development of Staking Mechanism"
      ]
    },
    {
      phase: "Phase 3",
      title: "Ecosystem Expansion",
      status: "upcoming",
      items: [
        "Cross-chain Bridge Development",
        "NFT Platform Integration",
        "Advanced DeFi Features",
        "Global Marketing Expansion"
      ]
    }
  ];

  return (
    <div className="min-h-screen relative text-white overflow-hidden">
      <div className="fixed inset-0 z-0">
        <RoadMapBackground />
      </div>
      
      {/* Navigation Bar */}
      <nav className="sticky top-0 z-50 bg-black/30 backdrop-blur-md border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div className="relative">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full blur opacity-75 animate-pulse"/>
                <img src="/logo.png" alt="Logo" className="relative h-8 w-8 sm:h-10 sm:w-10 rounded-full border border-white/10" />
              </div>
              <span className="ml-2 text-xl font-bold bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">
                MegaMeow
              </span>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-6">
              <Link to="/" className="text-gray-300 hover:text-white transition">Home</Link>
              <Link to="/missions" className="text-gray-300 hover:text-white transition">Missions</Link>
              <Link to="/wallet" className="text-gray-300 hover:text-white transition">Wallet</Link>
              <Link to="/roadmap" className="text-gray-300 hover:text-white transition">Roadmap</Link>
              <Link to="/token-allocation" className="text-gray-300 hover:text-white transition">Token Allocation</Link>
            </div>

            {/* Mobile menu button */}
            <div className="flex md:hidden items-center">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="text-gray-300 hover:text-white"
              >
                {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {mobileMenuOpen && (
          <div className="md:hidden bg-black/95 backdrop-blur-xl">
            <div className="px-4 pt-2 pb-4 space-y-4">
              <Link to="/" className="block text-gray-300 hover:text-white transition py-2">Home</Link>
              <Link to="/missions" className="block text-gray-300 hover:text-white transition py-2">Missions</Link>
              <Link to="/wallet" className="block text-gray-300 hover:text-white transition py-2">Wallet</Link>
              <Link to="/roadmap" className="block text-gray-300 hover:text-white transition py-2">Roadmap</Link>
              <Link to="/token-allocation" className="block text-gray-300 hover:text-white transition py-2">Token Allocation</Link>
            </div>
          </div>
        )}
      </nav>

      {/* Roadmap Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
        {/* Title Section */}
        <div className="text-center mb-16 relative z-10">
          <div className="relative inline-block">
            <div className="absolute -inset-4 bg-violet-600/20 blur-3xl rounded-full"></div>
            <h1 className="relative text-4xl sm:text-5xl lg:text-6xl font-bold mb-6 bg-gradient-to-r from-violet-300 via-fuchsia-200 to-blue-300 bg-clip-text text-transparent">
              Project Roadmap
            </h1>
          </div>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Our strategic vision for the future development
          </p>
        </div>

        {/* Roadmap Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {roadmapData.map((phase, index) => (
            <RoadMapPhase 
              key={index} 
              {...phase} 
              isMystery={index > 0} // First phase visible, others mysterious
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoadMapPage;