import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X, ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import FuturisticBackground from '../../FuturisticBackground';

const TokenDetailsPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="min-h-screen relative text-white">
      <FuturisticBackground />

      {/* Navigation Bar */}
      <nav className="relative bg-black/50 backdrop-blur-md border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div className="relative">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full blur opacity-75 animate-pulse" />
                <img
                  src="/logo.png"
                  alt="Logo"
                  className="relative h-8 w-8 sm:h-10 sm:w-10 rounded-full border border-white/10"
                />
              </div>
              <span className="ml-2 text-lg sm:text-xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent">
                MegaMeow
              </span>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-6">
              <Link to="/" className="text-gray-300 hover:text-white transition duration-300">
                Home
              </Link>
              <Link to="/missions" className="text-gray-300 hover:text-white transition duration-300">
                Missions
              </Link>
              <Link to="/wallet" className="text-gray-300 hover:text-white transition duration-300">
                Wallet
              </Link>
              <Link to="/roadmap" className="text-gray-300 hover:text-white transition duration-300">
                Roadmap
              </Link>
              <Link
                to="/token-allocation"
                className="text-gray-300 hover:text-white transition duration-300"
              >
                Token Allocation
              </Link>
            </div>

            {/* Mobile menu button */}
            <div className="flex md:hidden items-center">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="text-gray-300 hover:text-white"
              >
                {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {mobileMenuOpen && (
          <div className="md:hidden bg-black/95 backdrop-blur-xl">
            <div className="px-4 pt-2 pb-4 space-y-4">
              <Link
                to="/"
                className="block text-gray-300 hover:text-white transition duration-300 py-2"
              >
                Home
              </Link>
              <Link
                to="/missions"
                className="block text-gray-300 hover:text-white transition duration-300 py-2"
              >
                Missions
              </Link>
              <Link
                to="/wallet"
                className="block text-gray-300 hover:text-white transition duration-300 py-2"
              >
                Wallet
              </Link>
              <Link
                to="/roadmap"
                className="block text-gray-300 hover:text-white transition duration-300 py-2"
              >
                Roadmap
              </Link>
              <Link
                to="/token-allocation"
                className="block text-gray-300 hover:text-white transition duration-300 py-2"
              >
                Token Allocation
              </Link>
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
        {/* Back Button */}
        <motion.button
          onClick={() => navigate('/token-allocation')}
          className="mb-8 inline-flex items-center text-gray-300 hover:text-white transition group"
          whileHover={{ x: -5 }}
        >
          <ArrowLeft className="h-5 w-5 mr-2 group-hover:text-purple-400" />
          <span className="group-hover:text-purple-400">Back to Token Allocation</span>
        </motion.button>

        {/* Header */}
        <div className="text-center mb-16">
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6">
            <div className="relative inline-block">
              <div className="absolute -inset-4 bg-violet-600/30 blur-3xl rounded-full" />
              <span className="relative bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 bg-clip-text text-transparent animate-gradient-x">
                MegaMeow Token Distribution Plan
              </span>
            </div>
          </h1>
          <p className="text-white max-w-2xl mx-auto">
            This plan is designed to celebrate Chia’s milestones, foster community engagement, and
            support the sustainable growth of the MegaMeow ecosystem. The token distribution
            emphasizes transparency, functionality, and community-driven initiatives.
          </p>
        </div>

        {/* Content Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="relative"
        >
          <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 via-pink-500 to-blue-500 rounded-lg blur opacity-30" />
          <div className="relative bg-black/70 backdrop-blur-xl p-8 rounded-lg">
            <div className="prose prose-invert max-w-none">
              {/* Content goes here */}
              <div className="space-y-8">
                {/* Section 1 */}
                <div className="border-l-4 border-purple-500 pl-4">
                  <h2 className="text-2xl font-bold mb-4">
                    1. IPO Commemoration Wallet (35%)
                  </h2>
                  <p>
                    This wallet is reserved to commemorate Chia’s IPO as a major milestone in the
                    ecosystem. The tokens in this wallet are designated for symbolic burns to
                    celebrate the IPO.
                  </p>
                  <p className="font-semibold">If Chia IPO occurs:</p>
                  <ul className="list-disc list-inside">
                    <li>35% of tokens in this wallet will be burned.</li>
                    <li>
                      This burn symbolizes the milestone achievement and demonstrates the project’s
                      commitment to the ecosystem.
                    </li>
                  </ul>
                  <p className="font-semibold">If Chia IPO does not occur:</p>
                  <ul className="list-disc list-inside">
                    <li>
                      <strong>Year 1:</strong>
                      <ul className="list-disc list-inside ml-6">
                        <li>
                          10% of the tokens will be transferred to the Chia Milestone Reward Wallet
                          to incentivize community contributions.
                        </li>
                        <li>The remaining 25% will remain locked for the next year.</li>
                      </ul>
                    </li>
                    <li>
                      <strong>Year 2:</strong>
                      <ul className="list-disc list-inside ml-6">
                        <li>
                          If Chia IPO still has not occurred, another 10% will be transferred to the
                          Chia Milestone Reward Wallet, and the remaining 15% will stay locked for
                          the third year.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Year 3:</strong>
                      <ul className="list-disc list-inside ml-6">
                        <li>
                          If Chia IPO has not occurred by this time, the final 15% of the tokens
                          will be fully allocated to the Chia Milestone Reward Wallet to support
                          community engagement and ecosystem initiatives.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>

                {/* Section 2 */}
                <div className="border-l-4 border-pink-500 pl-4">
                  <h2 className="text-2xl font-bold mb-4">
                    2. Chia Milestone Reward Wallet (20%)
                  </h2>
                  <p>
                    This wallet is dedicated to celebrating milestones in the ecosystem and
                    rewarding community engagement.
                  </p>
                  <ul className="list-disc list-inside">
                    <li>
                      Tokens will be distributed transparently through community-focused activities,
                      such as governance votes, contributions to development, or other participatory
                      events.
                    </li>
                    <li>
                      Rewards are designed to increase engagement and ecosystem participation
                      without implying financial gain.
                    </li>
                  </ul>
                </div>

                {/* Section 3 */}
                <div className="border-l-4 border-blue-500 pl-4">
                  <h2 className="text-2xl font-bold mb-4">
                    3. Core Developer Reserve (15%)
                  </h2>
                  <p>
                    This allocation is dedicated to supporting the core team's ongoing efforts to
                    develop, maintain, and expand the MegaMeow ecosystem.
                  </p>
                  <p className="font-semibold">Details:</p>
                  <ul className="list-disc list-inside">
                    <li>
                      A total of 1,500,000,000 tokens (15% of the total supply) is allocated to the
                      Core Developer Reserve.
                    </li>
                    <li>
                      Token utilization from this reserve will not exceed 20% of the initial
                      allocation annually.
                    </li>
                    <li>Daily movements are capped at 1,500,000 tokens (0.1% of the reserve).</li>
                    <li>
                      Any transaction or movement exceeding 1,500,000 tokens (0.1% of the reserve)
                      in a single day will be publicly disclosed at least 14 calendar days prior to
                      execution.
                    </li>
                    <li>
                      The reserve usage will be strictly monitored and transparent, with updates
                      provided to the community to ensure accountability and alignment with
                      MegaMeow's long-term vision.
                    </li>
                  </ul>
                </div>

                {/* Section 4 */}
                <div className="border-l-4 border-green-500 pl-4">
                  <h2 className="text-2xl font-bold mb-4">
                    4. Community Developer Reserve (10%)
                  </h2>
                  <p>
                    This allocation is set aside for community-driven projects and independent
                    developers contributing to the Chia and MegaMeow ecosystems.
                  </p>
                  <p className="font-semibold">Details:</p>
                  <ul className="list-disc list-inside">
                    <li>
                      Proposals for funding will be reviewed and approved through decentralized
                      governance.
                    </li>
                    <li>
                      Priority will be given to projects that align with the ecosystem’s vision and
                      long-term growth.
                    </li>
                  </ul>
                </div>

                {/* Section 5 */}
                <div className="border-l-4 border-yellow-500 pl-4">
                  <h2 className="text-2xl font-bold mb-4">
                    5. MegaMeow Treasury Reserve (5%)
                  </h2>
                  <p>
                    This reserve is established as a strategic asset to ensure liquidity, stability,
                    and long-term sustainability of the MegaMeow ecosystem, while minimizing any
                    potential impact on the broader market.
                  </p>
                  <p className="font-semibold">Details:</p>
                  <ul className="list-disc list-inside">
                    <li>
                      The treasury will include holdings of other tokens, including those from the
                      Chia community, as part of MegaMeow's strategic reserve.
                    </li>
                    <li>
                      These reserves will not be used for market manipulation or speculative
                      activities. Instead, they will serve as a safeguard against excessive market
                      volatility and to protect the development team and community members from
                      significant market disruptions.
                    </li>
                    <li>
                      A portion of the tokens will be held on decentralized exchanges (DEXs) to
                      maintain liquidity and facilitate smooth trading.
                    </li>
                    <li>
                      The reserve will also be available to address unexpected needs, emergencies,
                      or other unforeseen circumstances, ensuring the project’s continued growth and
                      operational stability.
                    </li>
                    <li>
                      All reserve movements will be conducted transparently and in accordance with
                      applicable laws and regulations, with prior disclosure to the community when
                      necessary.
                    </li>
                  </ul>
                </div>

                {/* Section 6 */}
                <div className="border-l-4 border-indigo-500 pl-4">
                  <h2 className="text-2xl font-bold mb-4">
                    6. Exchange Token Reserve (10%)
                  </h2>
                  <p>
                    This allocation is designated to support token listings on exchanges and ensure
                    adequate market liquidity during the initial trading phases.
                  </p>
                  <p className="font-semibold">Details:</p>
                  <ul className="list-disc list-inside">
                    <li>
                      Tokens from this reserve will be strategically allocated to exchanges, with a
                      focus on decentralized platforms to align with community-driven values and
                      regulatory trends.
                    </li>
                    <li>
                      The designated wallet will be exclusively used for exchange-related purposes,
                      ensuring clear separation from other project reserves.
                    </li>
                    <li>
                      Should future regulations explicitly prohibit such allocations, the community
                      will hold a transparent vote to decide on the appropriate course of action,
                      including the potential burning of tokens held in this wallet to comply with
                      legal requirements.
                    </li>
                    <li>
                      All activities involving this reserve will be conducted in strict adherence to
                      applicable laws and with full transparency to the community.
                    </li>
                  </ul>
                </div>

                {/* Section 7 */}
                <div className="border-l-4 border-red-500 pl-4">
                  <h2 className="text-2xl font-bold mb-4">
                    7. Initial Liquidity (5%)
                  </h2>
                  <p>
                    This allocation establishes the foundational liquidity for MegaMeow token
                    trading and serves as a tribute to the Chia community, including farmers,
                    holders, and developers who have tirelessly contributed to its growth.
                  </p>
                  <p className="font-semibold">Details:</p>
                  <ul className="list-disc list-inside">
                    <li>
                      This reserve acknowledges the efforts of all those who have supported and
                      built the Chia ecosystem, laying the groundwork for the MegaMeow project's
                      launch.
                    </li>
                    <li>
                      The allocation will ensure stable liquidity to facilitate smooth trading and
                      provide a strong start for MegaMeow within the market.
                    </li>
                    <li>
                      As a symbolic gesture, this initial liquidity reflects MegaMeow’s commitment
                      to fostering collaboration and innovation within the broader blockchain
                      community.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      <style jsx>{`
        @keyframes gradient-x {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        .animate-gradient-x {
          animation: gradient-x 15s ease infinite;
          background-size: 200% 200%;
        }
      `}</style>
    </div>
  );
};

export default TokenDetailsPage;