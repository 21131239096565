import React, { useEffect, useRef } from 'react';

const RoadMapBackGround = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    let time = 0;
    const draw = () => {
      time += 0.005;

      // 清除画布
      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 绘制动态网格点
      const gridSize = 40;
      for (let i = 0; i < canvas.width; i += gridSize) {
        for (let j = 0; j < canvas.height; j += gridSize) {
          // 创建波浪效果
          const waveX = Math.sin(time + j * 0.05) * 10;
          const waveY = Math.cos(time + i * 0.05) * 10;
          
          const x = i + waveX;
          const y = j + waveY;
          
          const centerX = canvas.width / 2;
          const centerY = canvas.height / 2;
          const distanceFromCenter = Math.sqrt(
            Math.pow(x - centerX, 2) + 
            Math.pow(y - centerY, 2)
          );
          
          // 根据距离中心的远近调整点的不透明度
          const opacity = 0.3 - (distanceFromCenter / Math.max(canvas.width, canvas.height));
          if (opacity > 0) {
            const hue = (time * 20 + distanceFromCenter) % 360;
            ctx.fillStyle = `hsla(${hue}, 80%, 60%, ${opacity})`;
            ctx.beginPath();
            ctx.arc(x, y, 1.2, 0, Math.PI * 2);
            ctx.fill();
          }
        }
      }

      // 添加随机闪烁的星星效果
      for (let i = 0; i < 20; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        ctx.beginPath();
        ctx.arc(x, y, Math.random() * 1.5, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${Math.random() * 0.3})`;
        ctx.fill();
      }

      animationFrameId = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <canvas 
        ref={canvasRef} 
        className="fixed inset-0 w-full h-full"
        style={{ 
          background: 'radial-gradient(circle at 50% 50%, #1a0b2e, #000)',
          filter: 'contrast(1.2) brightness(1.1)'
        }}
      />
      <div className="absolute inset-0 mix-blend-screen opacity-20 pointer-events-none">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,rgba(255,0,128,0.2),transparent_70%)]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_70%,rgba(0,255,255,0.2),transparent_70%)]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(128,0,255,0.2),transparent_70%)]" />
      </div>
    </div>
  );
};

export default RoadMapBackGround;